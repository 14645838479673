var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',[_vm._v("Selecteer CSV bestand:")]),_c('input',{attrs:{"id":"csv","type":"file"},on:{"change":_vm.readFile}}),_c('v-data-table',{attrs:{"items":_vm.result,"headers":_vm.resultHeaders},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toDateString(item.date))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.amount))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('td',{class:item.error ? 'hasError' : 'noError'},[_c('ul',_vm._l((item.status),function(text,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(text)+" ")])}),0)])]}},{key:"item.confirm",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.error)?_c('v-btn',{attrs:{"disabled":item.confirmed || item.paid,"color":"success","small":""},on:{"click":function($event){return _vm.confirmPayment(item, index)}}},[_vm._v("Bevestig")]):_vm._e()]}},{key:"item.invoice.debit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoice && item.invoice.debit >= 0 ? _vm.toCurrency(item.invoice.debit) : "")+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }