<template>
  <div>
    <p>Selecteer CSV bestand:</p>
    <input @change="readFile" id="csv" type="file" />
    <v-data-table :items="result" :headers="resultHeaders">
      <template v-slot:[`item.date`]="{ item }">
        {{ toDateString(item.date) }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{ toCurrency(item.amount) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <td :class="item.error ? 'hasError' : 'noError'">
          <ul>
            <li v-for="(text, index) in item.status" :key="index">
              {{ text }}
            </li>
          </ul>
        </td>
      </template>
      <template v-slot:[`item.confirm`]="{ item, index }">
        <v-btn
          v-if="!item.error"
          @click="confirmPayment(item, index)"
          :disabled="item.confirmed || item.paid"
          color="success"
          small
          >Bevestig</v-btn
        >
      </template>
      <template v-slot:[`item.invoice.debit`]="{ item }">
        {{
          item.invoice && item.invoice.debit >= 0
            ? toCurrency(item.invoice.debit)
            : ""
        }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import {
  errorHandler,
  fetchGET,
  fetchPOST,
  toCurrency,
  toDateString
} from "../js/functions"
export default {
  name: "csvParser",
  data() {
    return {
      file: "",
      parsed: "",
      result: [],
      resultHeaders: [
        { text: "Status", value: "status", sortable: false },
        { text: "Datum", value: "date", sortable: false },
        { text: "Omschrijving", value: "description", sortable: false },
        { text: "Begunstigde", value: "name", sortable: false },
        { text: "Ontvangen", value: "amount", sortable: false },
        { text: "Naam", value: "invoice.name", sortable: false },
        { text: "Openstaand", value: "invoice.debit", sortable: false },
        { text: "Bevestigen", value: "confirm", sortable: false }
      ]
    }
  },
  methods: {
    toDateString(n) {
      return toDateString(n)
    },
    toCurrency(n) {
      return toCurrency(n)
    },
    readFile() {
      var fileInput = document.getElementById("csv")
      var reader = new FileReader()
      let thiss = this
      reader.onload = function() {
        thiss.file = reader.result
        thiss.parsed = thiss.processData(reader.result)
        let length = thiss.parsed.length
        let i = 0
        let arr = []
        for (i; i < length; i++) {
          let status = []
          let error = false
          let name = thiss.parsed[i][1]
          name = name.split(":")
          name = name[1]
          name = name.replaceAll('"', "")
          let type = thiss.parsed[i][5]
          type = type.split(":")
          type = type[1]
          let type2 = thiss.parsed[i][7]
          type2 = type2.split(":")
          type2 = type2[1]
          let d = thiss.parsed[i][0]
          d = d.split(":")
          d = d[1]
          d = d.replaceAll('"', "")
          let year = d.slice(0, 4)
          let month = d.slice(4, 6)
          let day = d.slice(6, 8)
          let date = new Date(`${year}-${month}-${day}`).toISOString()
          let amount = thiss.parsed[i][6]
          amount = amount.split(":")
          amount = amount[1]
          amount = amount.replaceAll('"', "")
          amount = amount.replaceAll(",", ".")
          let descr = thiss.parsed[i][8]
          let regex2 = descr.match("Omschrijving:s*(.*)s*IBAN:") //eslint-disable-line
          let description = regex2 && regex2.length ? regex2[1] : ""
          description = description.replaceAll('"', "")
          let numbers = description.match("[0-9]+")
          let invoiceNumber = numbers && numbers.length ? numbers[0] : ""
          if (type == '"Bij"' && type2 != '"Storting"') {
            if (invoiceNumber.length !== 9) {
              status.push("Geen factuurnummer")
              error = true
            }
            arr.push({
              name: name,
              description: description,
              type: type2,
              invoiceNumber: parseInt(invoiceNumber),
              amount: parseFloat(amount),
              date: date,
              status: status,
              error: error,
              confirmed: false,
              paid: false
            })
          }
        }
        console.log(arr)
        let invoices = arr.map(obj => obj.invoiceNumber)
        thiss.result = arr
        thiss.fetchInvoicesFromMap(invoices)
      }
      reader.readAsBinaryString(fileInput.files[0])
    },
    processData(allText) {
      var allTextLines = allText.split(/\r\n|\n/)
      var headers = allTextLines[0].split(";")
      var lines = []
      for (var i = 1; i < allTextLines.length; i++) {
        var data = allTextLines[i].split(";")
        if (data.length == headers.length) {
          var tarr = []
          for (var j = 0; j < headers.length; j++) {
            tarr.push(headers[j] + ":" + data[j])
          }
          lines.push(tarr)
        }
      }
      return lines
    },
    async fetchInvoicesFromMap(map) {
      let response = await fetchGET("fetchInvoicesFromMap", { invoices: map })
      for (let invoice of this.result) {
        let index = response.findIndex(
          res => invoice.invoiceNumber === res.invoiceNumber
        )
        if (index > -1) {
          invoice.invoice = response[index]
          invoice.paid = response[index].debit === 0 ? true : false
          invoice.status.push("Factuur gevonden")
          invoice.error = false
        } else {
          invoice.status.push("Factuur niet gevonden")
          invoice.error = true
        }
      }
    },
    async confirmPayment(payment, index) {
      try {
        let invoice = await fetchGET("fetchInvoices", {
          invoiceNumber: payment.invoiceNumber
        })
        invoice = invoice[0].results[0]
        let credit = payment.amount
        let debit = invoice.debit - credit
        debit = debit < 0.01 && debit > -0.01 ? 0 : debit
        let paid = debit === 0 ? true : false
        let date = payment.date.split("T")[0]
        const [year, month, day] = date.split("-")
        let dateFormatted = `${day}-${month}-${year}`
        invoice.payments.push({
          amount: credit,
          paid: paid,
          payMethod: "Bank",
          date: date,
          dateFormatted: dateFormatted
        })
        invoice.debit = debit
        this.result[index].confirmed = true
        this.result[index].paid = paid
        this.result[index].invoice.debit = debit
        invoice.status = debit == 0 ? 2 : 1
        let response = await fetchPOST("updateInvoice", invoice)
        console.log(response)
      } catch (e) {
        errorHandler(e, "Fout bij bevestigen")
      }
    }
  }
}
</script>
<style scoped>
.hasError {
  background: red;
  color: white;
}
.noError {
  background: green;
  color: white;
}
</style>